
@font-face {
    font-family: 'MonumentExtended';
    font-style: normal;
    font-weight: 100;
    src: url('MonumentExtended-Regular.otf'); /* IE9 Compat Modes */
    src: local('MonumentExtended'), url('MonumentExtended-Regular.otf') format('truetype'); /* Safari, Android, iOS */
    color: rgb(146, 146, 146);
}


@font-face {
    font-family: 'MonumentExtendedBold';
    font-style: normal;
    font-weight: 100;
    src: url('MonumentExtended-Ultrabold.otf'); /* IE9 Compat Modes */
    src: local('MonumentExtendedBold'), url('MonumentExtended-Ultrabold.otf') format('truetype'); /* Safari, Android, iOS */
    color: rgb(146, 146, 146);
}
